<template>
  <section class="Step_SectionPropertyOwnership">
    <fieldset>
      <div class="Step_SectionPropertyOwnership">
        <InfoTextHeading
          :headingText="$t('newProperty.propertyOwnership.headline')"
          :infoText="$t('infoTextDisabledFields')"
        >
        </InfoTextHeading>

        <div class="form-group">
          <div class="row_setp_main">
            <!-- InfoText -->
            <div class="col-md-10">
              <div class="data-form">
                <InfoText
                  :text="$t('newProperty.propertyOwnership.infoTextHtml')"
                  :htmlMode="true"
                />
              </div>
            </div>

            <div class="col-md-7">
              <label class="label-title custom_h2">
                <h2 class="subheading_title tooltip-icon">
                  {{ $t('newProperty.propertyOwnership.subheadline') }}
                </h2>
                <div class="tool_tip_box">
                  <Svgall
                    class="tool_tip_svg"
                    data-bs-toggle="dropdown"
                    data-target="info_owner_shl"
                    :name="'tooltip_icon'"
                    :color="primaryColor"
                  />
                  <div
                    class="dropdown-menu ToolTipTextBox"
                    id="info_owner_shl"
                  >
                    {{ $t('newProperty.propertyOwnership.tooltip1') }}<br /><br />
                    {{ $t('newProperty.propertyOwnership.tooltip2') }}<br /><br />
                    <a
                      href="https://hilfe.grundsteuer-digital.de/faq/muessen-fuer-ehepartner-welche-gemeinsam-grund-besitzen-zwei-vorerfassungsboegen-ausgefuellt-werden/"
                      target="_blank"
                    >
                      https://hilfe.grundsteuer-digital.de/faq/muessen-fuer-ehepartner-welche-gemeinsam-grund-besitzen-zwei-vorerfassungsboegen-ausgefuellt-werden/
                    </a>
                  </div>
                </div>
              </label>
            </div>

            <div
              v-for="(object, index) in form.owners"
              :key="index"
            >
              <!-- Eigentümer/Beteiligte 1 -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <hr class="subheading_devide" />
                <div class="deleteIcon delete_">
                  <h5 class="subheading_title_inner">
                    {{ $t('newProperty.propertyOwnership.group1_title') }} {{ index + 1 }}
                  </h5>
                  <Svgall
                    @click="removeOwner(index)"
                    :name="'delete'"
                    :color="primaryColor"
                    v-show="!isPropertyReadOnly"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip(0)"
                  />
                </div>
                <p
                  v-show="!isPropertyReadOnly"
                  style="text-align: right"
                >
                  <a
                    style="color: var(--api-background)"
                    href="#"
                    @click.prevent="fillAddressFromClient(index)"
                  >
                    {{ $t('newProperty.from_your_data') }}
                  </a>
                </p>
              </div>

              <!-- Anrede -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.salutation') }}
                  </label>
                  <select
                    v-model="form.owners[index].salutation"
                    :disabled="isPropertyReadOnly"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip(0)"
                  >
                    <option
                      v-for="option in salutation_options"
                      v-bind:key="option.code"
                      :value="option.code"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Titel/akademischer Grad -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.title') }}
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="form.owners[index].title"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('owner_' + index + '_title')"
                  >
                    {{ form.errors().get('owner_' + index + '_title') }}
                  </span>
                </div>
              </div>

              <!-- Geburtsdatum -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.dob') }}
                  </label>
                  <div class="input-icon">
                    <input
                      type="date"
                      autocomplete="off"
                      v-model="form.owners[index].dateOfBirth"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <Svgall
                      :name="'calendar'"
                      :color="primaryColor"
                      class="date-icon-firefox-fix"
                    />
                  </div>
                </div>
              </div>

              <!-- Vorname/Firma -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.firstName') }}
                    <span class="nf_starcolor">*</span>
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="form.owners[index].firstName"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('owner_' + index + '_firstName')"
                  >
                    {{ form.errors().get('owner_' + index + '_firstName') }}
                  </span>
                </div>
              </div>

              <!-- Name/Firma -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.lastName') }}
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="form.owners[index].lastName"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @mouseenter="setInfoTooltip(0)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('owner_' + index + '_lastName')"
                  >
                    {{ form.errors().get('owner_' + index + '_lastName') }}
                  </span>
                </div>
              </div>

              <!-- Straße -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyOwnershipStreet_' + index)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.street') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyOwnershipStreet_' + index"
                      :content="$t('newProperty.infoTooltip.street.content')"
                    />
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="form.owners[index].street"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyOwnershipStreet_' + index)"
                  />
                  <span class="help-text float-end"
                    >{{ form.owners[index].street ? form.owners[index].street.length : 0 }}/25</span
                  >
                  <span
                    class="error"
                    v-if="form.errors().has('owner_' + index + '_street')"
                  >
                    {{ form.errors().get('owner_' + index + '_street') }}
                  </span>
                </div>
              </div>

              <!-- Hausnummer -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyOwnershipHouseNumber_' + index)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.houseNumber') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyOwnershipHouseNumber_' + index"
                      :content="$t('newProperty.infoTooltip.houseNumber.content')"
                    />
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="form.owners[index].houseNumber"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyOwnershipHouseNumber_' + index)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('owner_' + index + '_houseNumber')"
                  >
                    {{ form.errors().get('owner_' + index + '_houseNumber') }}
                  </span>
                </div>
              </div>

              <!-- Telefonnummer -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.phone') }}
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="form.owners[index].phone"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span class="help-text float-end">{{ $t('newProperty.infoTooltip.optional_help_text') }}</span>
                  <span
                    class="error"
                    v-if="form.errors().has('owner_' + index + '_phone')"
                  >
                    {{ form.errors().get('owner_' + index + '_phone') }}
                  </span>
                </div>
              </div>

              <!-- PLZ -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.zip') }}
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="form.owners[index].zip"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @blur="searchCity(index)"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('owner_' + index + '_zip')"
                  >
                    {{ form.errors().get('owner_' + index + '_zip') }}
                  </span>
                </div>
              </div>

              <!-- Postfach -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.postbox') }}
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="form.owners[index].postbox"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('owner_' + index + '_postbox')"
                  >
                    {{ form.errors().get('owner_' + index + '_postbox') }}
                  </span>
                </div>
              </div>

              <!-- Stadt -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.city') }}
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="form.owners[index].city"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('owner_' + index + '_city')"
                  >
                    {{ form.errors().get('owner_' + index + '_city') }}
                  </span>
                </div>
              </div>

              <!-- Bundesland -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.propertyOwnership.state') }}</label>
                  <select
                    v-model="form.owners[index].federalStateUid"
                    :disabled="isPropertyReadOnly"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip(0)"
                  >
                    <option
                      value="0"
                      disabled
                      selected
                    >
                      {{ $t('select.select_any') }}
                    </option>
                    <option
                      v-for="option in federal_states"
                      v-bind:key="option.value"
                      :value="option.value"
                    >
                      {{ $t('federal_states.' + option.value) }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Land -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.country') }}
                  </label>
                  <select
                    v-model="form.owners[index].countryUid"
                    :disabled="isPropertyReadOnly"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip(0)"
                  >
                    <option
                      value="0"
                      disabled
                      selected
                    >
                      {{ $t('select.select_any') }}
                    </option>
                    <option
                      v-for="option in $store.state.Properties.arrCountries.countries"
                      v-bind:key="option.code"
                      :value="option.code"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Wohnsitz-/Betriebsstätten-Finanzamt -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyOwnershipFinanceAgency_' + index)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.financeAgency') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyOwnershipFinanceAgency_' + index"
                      :content="$t('newProperty.infoTooltip.ownership.financeAgency.content')"
                    />
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="form.owners[index].financeAgency"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyOwnershipFinanceAgency_' + index)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('owner_' + index + '_financeAgency')"
                  >
                    {{ form.errors().get('owner_' + index + '_financeAgency') }}
                  </span>
                </div>
              </div>

              <!-- Steuernummer -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyOwnershipTaxNumber_' + index)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.taxNumber') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyOwnershipTaxNumber_' + index"
                      :content="$t('newProperty.infoTooltip.ownership.taxNumber.content')"
                      :url="$t('newProperty.infoTooltip.ownership.taxNumber.url')"
                    />
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="form.owners[index].taxNumber"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyOwnershipTaxNumber_' + index)"
                  />
                  <span class="help-text float-end">{{ $t('newProperty.infoTooltip.available_help_text') }}</span>
                  <span
                    class="error"
                    v-if="form.errors().has('owner_' + index + '_taxNumber')"
                  >
                    {{ form.errors().get('owner_' + index + '_taxNumber') }}
                  </span>
                </div>
              </div>

              <!-- Identifikationsnummer-->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyOwnershipTaxIdentificationNumber_' + index)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.taxIdentificationNumber') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyOwnershipTaxIdentificationNumber_' + index"
                      :content="$t('newProperty.infoTooltip.ownership.taxIdentificationNumber.content')"
                      :imagePath="require('@/assets/img/infotooltip/tax-id-number.png')"
                    />
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="form.owners[index].taxIdentificationNumber"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyOwnershipTaxIdentificationNumber_' + index)"
                  />
                  <span class="help-text float-end">{{ $t('newProperty.infoTooltip.available_help_text') }}</span>
                  <span
                    class="error"
                    v-if="form.errors().has('owner_' + index + '_taxIdentificationNumber')"
                  >
                    {{ form.errors().get('owner_' + index + '_taxIdentificationNumber') }}
                  </span>
                </div>
              </div>

              <!-- Anteil am Grundstück -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <label class="subheading_title __TopSpace">
                  {{ $t('newProperty.propertyOwnership.group2_title') }}
                </label>
              </div>

              <!-- Anteil Zähler -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyOwnershipShareOfOwnershipCounter_' + index)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.area_of_the_land_counter') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyOwnershipShareOfOwnershipCounter_' + index"
                      :content="$t('newProperty.infoTooltip.ownership.shareOfOwnershipCounter.content')"
                      :url="$t('newProperty.infoTooltip.ownership.shareOfOwnershipCounter.url')"
                      maxHeight="340px"
                    />
                  </label>
                  <input
                    type="number"
                    autocomplete="off"
                    v-model="form.owners[index].area_of_the_land_counter"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyOwnershipShareOfOwnershipCounter_' + index)"
                  />
                </div>
              </div>

              <!-- Anteil Nenner -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyOwnershipShareOfOwnershipDenominator_' + index)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.area_of_the_land_denominator') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyOwnershipShareOfOwnershipDenominator_' + index"
                      :content="$t('newProperty.infoTooltip.ownership.shareOfOwnershipDenominator.content')"
                      :url="$t('newProperty.infoTooltip.ownership.shareOfOwnershipDenominator.url')"
                      maxHeight="320px"
                    />
                  </label>
                  <input
                    type="number"
                    autocomplete="off"
                    v-model="form.owners[index].area_of_the_land_denominator"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyOwnershipShareOfOwnershipDenominator_' + index)"
                  />
                </div>
              </div>

              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form data-form-flex">
                  <div class="switch mb-2">
                    <input
                      type="checkbox"
                      v-model="form.owners[index].is_legal_representative"
                      true-value="1"
                      false-value="0"
                      :disabled="isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="slider round"
                      @click.prevent="representativeCheckboxChanged(index)"
                    ></span>
                  </div>
                  <span class="checkbox-label-bold">
                    {{ representativeCheckboxLabel(index) }}
                  </span>
                </div>
              </div>

              <!-- Gegebenfalls gesetzlich vertreten durch: -->
              <div v-show="form.owners[index].is_legal_representative === '1'">
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <hr class="subheading_devide" />
                  <label class="subheading_title __TopSpace">{{
                    $t('newProperty.propertyOwnership.group3_title')
                  }}</label>
                </div>

                <!-- Anrede -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyOwnership.rep_salutation') }}
                    </label>
                    <select
                      v-model="form.owners[index].representative.salutation"
                      :disabled="isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    >
                      <option
                        value="0"
                        disabled
                        selected
                      >
                        {{ $t('select.select_any') }}
                      </option>
                      <option
                        v-for="option in salutation_user_options"
                        v-bind:key="option.code"
                        :value="option.code"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- Titel/akademischer Grad -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyOwnership.rep_title') }}
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      v-model="form.owners[index].representative.title"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('owner_' + index + '_rep_title')"
                    >
                      {{ form.errors().get('owner_' + index + '_rep_title') }}
                    </span>
                  </div>
                </div>

                <!-- Geburtsdatum -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyOwnership.rep_dob') }}
                    </label>
                    <div class="input-icon">
                      <input
                        type="date"
                        autocomplete="off"
                        v-model="form.owners[index].representative.dateOfBirth"
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <Svgall
                        :name="'calendar'"
                        :color="primaryColor"
                        class="date-icon-firefox-fix"
                      />
                    </div>
                  </div>
                </div>

                <!-- Vorname/Firma -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyOwnership.rep_firstName') }}
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      v-model="form.owners[index].representative.firstName"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('owner_' + index + '_rep_firstName')"
                    >
                      {{ form.errors().get('owner_' + index + '_rep_firstName') }}
                    </span>
                  </div>
                </div>

                <!-- Name/Firma -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyOwnership.rep_lastName') }}
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      v-model="form.owners[index].representative.lastName"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('owner_' + index + '_rep_lastName')"
                    >
                      {{ form.errors().get('owner_' + index + '_rep_lastName') }}
                    </span>
                  </div>
                </div>

                <!-- Straße -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip('propertyOwnershipRepStreet_' + index)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyOwnership.rep_street') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyOwnershipRepStreet_' + index"
                        :content="$t('newProperty.infoTooltip.street.content')"
                      />
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      v-model="form.owners[index].representative.street"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip('propertyOwnershipRepStreet_' + index)"
                    />
                    <span class="help-text float-end"
                      >{{
                        form.owners[index].representative.street ? form.owners[index].representative.street.length : 0
                      }}/25</span
                    >
                    <span
                      class="error"
                      v-if="form.errors().has('owner_' + index + '_rep_street')"
                    >
                      {{ form.errors().get('owner_' + index + '_rep_street') }}
                    </span>
                  </div>
                </div>

                <!-- Hausnummer -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip('propertyOwnershipRepHouseNumber_' + index)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyOwnership.rep_houseNumber') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyOwnershipRepHouseNumber_' + index"
                        :content="$t('newProperty.infoTooltip.houseNumber.content')"
                      />
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      v-model="form.owners[index].representative.houseNumber"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip('propertyOwnershipRepHouseNumber_' + index)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('owner_' + index + '_rep_houseNumber')"
                    >
                      {{ form.errors().get('owner_' + index + '_rep_houseNumber') }}
                    </span>
                  </div>
                </div>

                <!-- Telefonnummer -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyOwnership.rep_phone') }}
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      v-model="form.owners[index].representative.phone"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span class="help-text float-end">{{ $t('newProperty.infoTooltip.optional_help_text') }}</span>
                    <span
                      class="error"
                      v-if="form.errors().has('owner_' + index + '_rep_phone')"
                    >
                      {{ form.errors().get('owner_' + index + '_rep_phone') }}
                    </span>
                  </div>
                </div>

                <!-- PLZ -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyOwnership.rep_zip') }}
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      v-model="form.owners[index].representative.zip"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @blur="searchCity(index, true)"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('owner_' + index + '_rep_zip')"
                    >
                      {{ form.errors().get('owner_' + index + '_rep_zip') }}
                    </span>
                  </div>
                </div>

                <!-- Postfach -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyOwnership.rep_postbox') }}
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      v-model="form.owners[index].representative.postbox"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('owner_' + index + '_rep_postbox')"
                    >
                      {{ form.errors().get('owner_' + index + '_rep_postbox') }}
                    </span>
                  </div>
                </div>

                <!-- Stadt -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyOwnership.rep_city') }}
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      v-model="form.owners[index].representative.city"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('owner_' + index + '_rep_city')"
                    >
                      {{ form.errors().get('owner_' + index + '_rep_city') }}
                    </span>
                  </div>
                </div>

                <!-- Land -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyOwnership.rep_country') }}
                    </label>
                    <select
                      v-model="form.owners[index].representative.countryUid"
                      :disabled="isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    >
                      <option
                        value="0"
                        disabled
                        selected
                      >
                        {{ $t('select.select_any') }}
                      </option>
                      <option
                        v-for="option in $store.state.Properties.arrCountries.countries"
                        v-bind:key="option.code"
                        :value="option.code"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <br v-show="index < form.owners.length - 1" />
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="add-field">
                <button
                  class="add-field-button hover-tooltip"
                  :disabled="isPropertyReadOnly"
                  @click="addOwner"
                  @focusin="setInfoTooltip(0)"
                >
                  <Svgall
                    :name="'add_more'"
                    :color="primaryColor"
                  />
                  <span>{{ $t('newProperty.addMore') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="text_left step-button-section"
          @mouseenter="setInfoTooltip(0)"
        >
          <div>
            <button
              type="button"
              @click.prevent="cancel"
              class="step-button-style border-button-style hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.abort') }}
            </button>
            <button
              type="button"
              @click.prevent="save"
              class="step-button-style border-button-style hover-tooltip"
              v-show="!isPropertyReadOnly"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.save') }}
            </button>
            <button
              type="button"
              v-show="back_button_activated"
              @click.prevent="callForPrecedingStep"
              class="step-button-style border-button-style"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.back') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              class="step-button-style hover-tooltip"
              :style="[primaryColor ? { background: primaryColor } : '']"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              class="step-button-enter hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue_enter') }}
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </section>
</template>

<script type="text/javascript">
import form from 'vuejs-form'
import i18n from '@/i18n'
import InfoTooltip from '@/components/helper/InfoTooltip'
import ShowHideTooltip from '@/mixins/ShowHideTooltip'
import DateIconFirefoxFix from '@/mixins/DateIconFirefoxFix'
const { t } = i18n.global
import { mapGetters, mapActions } from 'vuex'
import { defaultPropertyOwnership } from '../../store/defaults/properties/DefaultPropertyOwnership'
import InfoTextHeading from '@/components/helper/InfoTextHeading'
import InfoText from '@/components/helper/InfoText'

export default {
  name: 'PropertyOwnership',
  components: { InfoTextHeading, InfoText, InfoTooltip },
  mixins: [ShowHideTooltip, DateIconFirefoxFix],
  emits: ['callForNextStep', 'callForPrecedingStep', 'callForSaveProperty', 'cancelPropVisible'],
  data() {
    return {
      primaryColor: '',
      fontColor: '',
      form: form({
        owners: [
          {
            id: 0,
            salutation: '0',
            title: '',
            dateOfBirth: null,
            firstName: '',
            lastName: '',
            street: '',
            houseNumber: '',
            phone: '',
            zip: '',
            postbox: '',
            city: '',
            federalStateUid: '0',
            countryUid: '0',
            financeAgency: '',
            taxNumber: '',
            taxIdentificationNumber: '',
            area_of_the_land_counter: '',
            area_of_the_land_denominator: '',
            is_legal_representative: '0',
            representative: {
              salutation: '0',
              title: '',
              dateOfBirth: null,
              firstName: '',
              lastName: '',
              street: '',
              houseNumber: '',
              phone: '',
              zip: '',
              postbox: '',
              city: '',
              countryUid: '0',
            },
          },
        ], // set form rules and messages in method "prepareForValidate()"
      }),
      federal_states: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 },
        { value: 10 },
        { value: 11 },
        { value: 12 },
        { value: 13 },
        { value: 14 },
        { value: 15 },
        { value: 16 },
      ],
      salutationMatches: {
        mr: '2',
        mrs: '3',
        '-': '1',
        company: '7',
        joint_venture: '8',
        working_group: '9',
        property_association: '10',
        civil_law_partnership: '11',
        law_firm: '12',
        joint_practice: '13',
        farming: '14',
        homeowners: '15',
        partnership: '16',
        shipping: '17',
      },
      back_button_activated: false,
    }
  },
  mounted() {
    this.initForm()
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : ''
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.fontColor : ''
    this.showHide()
  },
  beforeUnmount() {
    this.saveForm()
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getFormPropertyOwnership', 'isPropertyReadOnly', 'infoTooltip']),

    isNewProperty() {
      return this.$parent.isNewProperty
    },

    salutation_options() {
      let myRawOptions = this.$tm('newProperty.propertyOwnership.salutation_options')
      let myOptions = []
      for (let k of myRawOptions) {
        myOptions.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
        })
      }
      return myOptions
    },

    salutation_user_options() {
      let myRawOptions = this.$tm('newProperty.propertyOwnership.salutation_user_options')
      let myOptions = []
      for (let k of myRawOptions) {
        myOptions.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
        })
      }
      return myOptions
    },

    isShowNextStepButton() {
      let isShow = true
      if (this.$parent.step === this.$parent.maxStep && this.isPropertyReadOnly) {
        isShow = false
      }
      return isShow
    },
  },
  watch: {
    getFormPropertyOwnership() {
      this.initForm()
    },
  },
  methods: {
    ...mapActions(['resetFormPropertyOwnership', 'apiGetCountries', 'showToast', 'changeInfoTooltip', 'cityLookup']),

    setInfoTooltip(val) {
      this.changeInfoTooltip(val)
    },

    initForm() {
      this.removeFlatFormFields()
      for (const [key, value] of Object.entries(this.$store.state.Properties.formPropertyOwnership)) {
        if (typeof value !== 'undefined') {
          this.form[key] = JSON.parse(JSON.stringify(value))
        }
      }
    },

    saveForm() {
      let myKeys = []
      this.resetFormPropertyOwnership()
      myKeys = Object.keys(this.$store.state.Properties.formPropertyOwnership)
      if (typeof myKeys === 'object') {
        myKeys.forEach(function (key) {
          if (typeof this.form[key] !== 'undefined') {
            this.$store.state.Properties.formPropertyOwnership[key] = JSON.parse(JSON.stringify(this.form[key]))
          }
        }, this)
      }
    },

    cancel() {
      this.$emit('cancelPropVisible')
    },

    callForPrecedingStep() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForPrecedingStep')
      } else {
        this.$emit('callForPrecedingStep')
      }
    },

    callForNextStep() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForNextStep')
      } else {
        this.$emit('callForNextStep')
      }
    },

    save() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForSaveProperty')
      }
    },

    prepareForValidate() {
      let myFlatFields = {}

      //remove existing flat fields first
      this.removeFlatFormFields()

      //add flat fields
      this.form.owners.forEach(function (owner, ownerIndex) {
        myFlatFields['owner_' + ownerIndex.toString() + '_title'] = owner.title
        this.form.validatorInstance.addMessage(
          'owner_' + ownerIndex.toString() + '_title.max',
          t('newProperty.propertyOwnership.messages.title_max'),
        )
        this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_title', 'max: 15')

        myFlatFields['owner_' + ownerIndex.toString() + '_firstName'] = owner.firstName
        this.form.validatorInstance.addMessage(
          'owner_' + ownerIndex.toString() + '_firstName.max',
          t('newProperty.propertyOwnership.messages.firstName_max'),
        )
        this.form.validatorInstance.addMessage(
          'owner_' + ownerIndex.toString() + '_firstName.required',
          t('newProperty.propertyOwnership.messages.firstName_required'),
        )
        this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_firstName', ['required', 'max: 25'])

        myFlatFields['owner_' + ownerIndex.toString() + '_lastName'] = owner.lastName
        this.form.validatorInstance.addMessage(
          'owner_' + ownerIndex.toString() + '_lastName.max',
          t('newProperty.propertyOwnership.messages.lastName_max'),
        )
        this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_lastName', 'max: 25')

        myFlatFields['owner_' + ownerIndex.toString() + '_street'] = owner.street
        this.form.validatorInstance.addMessage(
          'owner_' + ownerIndex.toString() + '_street.max',
          t('newProperty.propertyOwnership.messages.street_max'),
        )
        this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_street', 'max: 25')

        myFlatFields['owner_' + ownerIndex.toString() + '_houseNumber'] = owner.houseNumber
        this.form.validatorInstance.addMessage(
          'owner_' + ownerIndex.toString() + '_houseNumber.max',
          t('newProperty.propertyOwnership.messages.houseNumber_max'),
        )
        this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_houseNumber', 'max: 25')

        myFlatFields['owner_' + ownerIndex.toString() + '_phone'] = owner.phone
        this.form.validatorInstance.addMessage(
          'owner_' + ownerIndex.toString() + '_phone.max',
          t('newProperty.propertyOwnership.messages.phone_max'),
        )
        this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_phone', 'max: 25')

        myFlatFields['owner_' + ownerIndex.toString() + '_zip'] = owner.zip
        this.form.validatorInstance.addMessage(
          'owner_' + ownerIndex.toString() + '_zip.max',
          t('newProperty.propertyOwnership.messages.zip_max'),
        )
        this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_zip', 'max: 12')

        myFlatFields['owner_' + ownerIndex.toString() + '_postbox'] = owner.postbox
        this.form.validatorInstance.addMessage(
          'owner_' + ownerIndex.toString() + '_postbox.max',
          t('newProperty.propertyOwnership.messages.postbox_max'),
        )
        this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_postbox', 'max: 6')

        myFlatFields['owner_' + ownerIndex.toString() + '_city'] = owner.city
        this.form.validatorInstance.addMessage(
          'owner_' + ownerIndex.toString() + '_city.max',
          t('newProperty.propertyOwnership.messages.city_max'),
        )
        this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_city', 'max: 25')

        myFlatFields['owner_' + ownerIndex.toString() + '_financeAgency'] = owner.financeAgency
        this.form.validatorInstance.addMessage(
          'owner_' + ownerIndex.toString() + '_financeAgency.max',
          t('newProperty.propertyOwnership.messages.financeAgency_max'),
        )
        this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_financeAgency', 'max: 25')

        myFlatFields['owner_' + ownerIndex.toString() + '_taxNumber'] = owner.taxNumber
        this.form.validatorInstance.addMessage(
          'owner_' + ownerIndex.toString() + '_taxNumber.max',
          t('newProperty.propertyOwnership.messages.taxNumber_max'),
        )
        this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_taxNumber', 'max: 13')

        myFlatFields['owner_' + ownerIndex.toString() + '_taxIdentificationNumber'] = owner.taxIdentificationNumber
        this.form.validatorInstance.addMessage(
          'owner_' + ownerIndex.toString() + '_taxIdentificationNumber.max',
          t('newProperty.propertyOwnership.messages.taxIdentificationNumber_max'),
        )
        this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_taxIdentificationNumber', 'max: 11')

        if (typeof owner.representative === 'object') {
          myFlatFields['owner_' + ownerIndex.toString() + '_rep_title'] = owner.representative.title
          this.form.validatorInstance.addMessage(
            'owner_' + ownerIndex.toString() + '_rep_title.max',
            t('newProperty.propertyOwnership.messages.title_max'),
          )
          this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_rep_title', 'max: 15')

          myFlatFields['owner_' + ownerIndex.toString() + '_rep_firstName'] = owner.representative.firstName
          this.form.validatorInstance.addMessage(
            'owner_' + ownerIndex.toString() + '_rep_firstName.max',
            t('newProperty.propertyOwnership.messages.firstName_max'),
          )
          this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_rep_firstName', 'max: 25')

          myFlatFields['owner_' + ownerIndex.toString() + '_rep_lastName'] = owner.representative.lastName
          this.form.validatorInstance.addMessage(
            'owner_' + ownerIndex.toString() + '_rep_lastName.max',
            t('newProperty.propertyOwnership.messages.lastName_max'),
          )
          this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_rep_lastName', 'max: 25')

          myFlatFields['owner_' + ownerIndex.toString() + '_rep_street'] = owner.representative.street
          this.form.validatorInstance.addMessage(
            'owner_' + ownerIndex.toString() + '_rep_street.max',
            t('newProperty.propertyOwnership.messages.street_max'),
          )
          this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_rep_street', 'max: 25')

          myFlatFields['owner_' + ownerIndex.toString() + '_rep_houseNumber'] = owner.representative.houseNumber
          this.form.validatorInstance.addMessage(
            'owner_' + ownerIndex.toString() + '_rep_houseNumber.max',
            t('newProperty.propertyOwnership.messages.houseNumber_max'),
          )
          this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_rep_houseNumber', 'max: 25')

          myFlatFields['owner_' + ownerIndex.toString() + '_rep_phone'] = owner.representative.phone
          this.form.validatorInstance.addMessage(
            'owner_' + ownerIndex.toString() + '_rep_phone.max',
            t('newProperty.propertyOwnership.messages.phone_max'),
          )
          this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_rep_phone', 'max: 25')

          myFlatFields['owner_' + ownerIndex.toString() + '_rep_zip'] = owner.representative.zip
          this.form.validatorInstance.addMessage(
            'owner_' + ownerIndex.toString() + '_rep_zip.max',
            t('newProperty.propertyOwnership.messages.zip_max'),
          )
          this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_rep_zip', 'max: 12')

          myFlatFields['owner_' + ownerIndex.toString() + '_rep_postbox'] = owner.representative.postbox
          this.form.validatorInstance.addMessage(
            'owner_' + ownerIndex.toString() + '_rep_postbox.max',
            t('newProperty.propertyOwnership.messages.postbox_max'),
          )
          this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_rep_postbox', 'max: 6')

          myFlatFields['owner_' + ownerIndex.toString() + '_rep_city'] = owner.representative.city
          this.form.validatorInstance.addMessage(
            'owner_' + ownerIndex.toString() + '_rep_city.max',
            t('newProperty.propertyOwnership.messages.city_max'),
          )
          this.form.validatorInstance.addRule('owner_' + ownerIndex.toString() + '_rep_city', 'max: 25')
        }
      }, this)

      this.form.set(myFlatFields)
    },

    removeFlatFormFields() {
      Object.keys(this.form.all()).forEach((key) => {
        if (key.startsWith('owner_')) {
          this.form.forget(key)
        }
      })
      this.form.rules({})
    },

    invokeReference(ref) {
      this.$nextTick(() => {
        if (!this.$refs[ref]) return
        this.$refs[ref].focus()
      })
    },
    onlyNumber($event) {
      let code = $event.code ? $event.code : $event.which
      let key = $event.key
      if (code === 'KeyE' || key === 'e' || key === 'E') {
        $event.preventDefault()
      }
    },

    removeOwner(index) {
      if (typeof index === 'number' && index >= 0 && index < this.form.owners.length) {
        this.form.owners.splice(index, 1)
      }
    },

    addOwner() {
      let myObject = JSON.parse(JSON.stringify(defaultPropertyOwnership.owners[0]))
      this.form.owners.push(myObject)
    },

    fillAddressFromClient(ownerIndex) {
      if (
        typeof ownerIndex === 'number' &&
        ownerIndex >= 0 &&
        Array.isArray(this.form.owners) &&
        ownerIndex < this.form.owners.length
      ) {
        if (
          typeof this.getCurrentUser.client.salutation !== 'undefined' &&
          typeof this.salutationMatches[this.getCurrentUser.client.salutation] !== 'undefined'
        ) {
          this.form.owners[ownerIndex].salutation = this.salutationMatches[this.getCurrentUser.client.salutation]
        }

        if (typeof this.getCurrentUser.client.vatId === 'string') {
          this.form.owners[ownerIndex].taxNumber = this.getCurrentUser.client.vatId
        }

        if (typeof this.getCurrentUser.client.clientType === 'string') {
          switch (this.getCurrentUser.client.clientType) {
            case 'private':
              this.form.owners[ownerIndex].title = this.getCurrentUser.client.clientTitle
              this.form.owners[ownerIndex].dateOfBirth = this.getCurrentUser.client.dob
              this.form.owners[ownerIndex].firstName = this.getCurrentUser.client.firstName
              this.form.owners[ownerIndex].lastName = this.getCurrentUser.client.lastName
              this.form.owners[ownerIndex].taxIdentificationNumber = this.getCurrentUser.client.clientNumber
              break
            case 'business':
              this.form.owners[ownerIndex].firstName = this.getCurrentUser.client.companyName
              break
          }
        }
        this.form.owners[ownerIndex].street = this.getCurrentUser.client.contact.street
        this.form.owners[ownerIndex].houseNumber = this.getCurrentUser.client.contact.houseNumber
        this.form.owners[ownerIndex].phone = this.getCurrentUser.client.contact.phone
        this.form.owners[ownerIndex].zip = this.getCurrentUser.client.contact.zipCode
        this.form.owners[ownerIndex].postbox = this.getCurrentUser.client.contact.poBox
        this.form.owners[ownerIndex].city = this.getCurrentUser.client.contact.city
        this.form.owners[ownerIndex].countryUid = this.getCurrentUser.client.contact.countryUid
      }
    },

    representativeCheckboxChanged(index) {
      this.form.owners[index].is_legal_representative =
        this.form.owners[index].is_legal_representative === '0' ? '1' : '0'
    },

    representativeCheckboxLabel(index) {
      return this.$t('newProperty.propertyOwnership.rep_toggle').replace('%index%', index + 1)
    },

    searchCity(index, isRepresentative = false) {
      if (
        typeof index !== 'number'
        || index < 0
        || typeof this.form.owners[index] === 'undefined'
      ) {
        return;
      }

      let baseObject = (isRepresentative === true)
        ? this.form.owners[index].representative
        : this.form.owners[index];

      this.cityLookup(baseObject.zip).then((cities) => {
        if (Object.keys(cities).length > 0) {
          baseObject.city = cities[0].name;
        }
      })
    },

  },
}
</script>

<style type="text/css">
textarea.textarea-field {
  border: 1px solid #bbbbbb;
  height: 150px;
  width: 100%;
}

.Step_SectionPropertyOwnership .tooltip-icon img {
  height: 18px;
  margin-left: 5px;
}

label.label-title.tooltip-icon {
  position: relative;
}

.ToolTipTextBox {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: none;
  max-width: 600px;
  padding: 20px;
  padding: 15px !important;
  position: absolute;
  right: 0;
  top: 35px;
  transform: translate3d(0px, 40px, 0px) !important;
  z-index: 1;
}

.deleteIcon {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 0px !important;
}

.custom_h2 {
  align-items: center;
  display: flex !important;
}
.custom_h2 h2 {
  margin: 0px !important;
}
/*.Step_SectionPropertyOwnership .tooltip-icon img:hover + .ToolTipTextBox {
    display: block;
}*/

.switch {
  border-radius: 20px;

  box-sizing: border-box;
  color: #333333;
  display: inline-block;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  height: 33px;
  line-height: normal;
  margin-right: 15px;
  margin-top: 10px;
  position: relative;
  text-align: center;

  width: 69px;
}

.switch input {
  height: 0;
  opacity: 0;
  width: 0;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  background-color: white;
  bottom: 4px;
  content: '';
  height: 26px;
  left: 4px;
  position: absolute;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 26px;
}

input:checked + .slider {
  background-color: var(--api-background);
}

input:checked + .slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.data-form-flex {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.checkbox-label-bold {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.tooltip-title {
  position: relative;
}
</style>
