<template>
  <section class="Step_SectionPropertyCommunity">
    <fieldset>
      <div class="Step_SectionPropertyCommunity">
        <InfoTextHeading
          :headingText="$t('newProperty.propertyCommunity.headline')"
          :infoText="$t('infoTextDisabledFields')"
        >
        </InfoTextHeading>

        <div class="form-group">
          <div class="row_setp_main">
            <!-- InfoText -->
            <div class="col-md-10">
              <div class="data-form">
                <InfoText
                  :text="$t('newProperty.propertyCommunity.infoTextHtml')"
                  :htmlMode="true"
                />
              </div>
            </div>

            <!-- Anrede -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyCommunity.salutation') }} </label>
                <select
                  ref="community_salutation"
                  v-model.number="form.salutation"
                  :disabled="isPropertyReadOnly"
                  class="hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                >
                  <option
                    v-for="option in ownership_salutation_options"
                    v-bind:key="option.code"
                    :value="option.code"
                  >
                    {{ option.name }}
                  </option>
                </select>
                <span
                  class="error"
                  v-if="form.errors().has('salutation')"
                >
                  {{ form.errors().get('salutation') }}
                </span>
              </div>
            </div>

            <!-- Name der Gemeinschaft -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyCommunity.name') }}</label>
                <input
                  type="text"
                  v-model="form.name"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  ref="community_name"
                  @focusin="setInfoTooltip(0)"
                />
                <span
                  class="error"
                  v-if="form.errors().has('name')"
                >
                  {{ form.errors().get('name') }}
                </span>
              </div>
            </div>

            <!-- Straße -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip('propertyCommunityStreet')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyCommunity.street') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyCommunityStreet'"
                    :content="$t('newProperty.infoTooltip.street.content')"
                  />
                </label>
                <input
                  type="text"
                  v-model="form.street"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip('propertyCommunityStreet')"
                />
                <span class="help-text float-end">{{ form.street ? form.street.length : 0 }}/25</span>
                <span
                  class="error"
                  v-if="form.errors().has('street')"
                >
                  {{ form.errors().get('street') }}
                </span>
              </div>
            </div>

            <!-- Hausnummer -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip('propertyCommunityHouseNumber')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyCommunity.house_number') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyCommunityHouseNumber'"
                    :content="$t('newProperty.infoTooltip.houseNumber.content')"
                  />
                </label>
                <input
                  type="text"
                  autocomplete="off"
                  v-model="form.house_number"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip('propertyCommunityHouseNumber')"
                />
                <span
                  class="error"
                  v-if="form.errors().has('house_number')"
                >
                  {{ form.errors().get('house_number') }}
                </span>
              </div>
            </div>

            <!-- PLZ -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyCommunity.zip') }}</label>
                <input
                  type="text"
                  v-model="form.zip"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @blur="searchCity"
                  @focusin="setInfoTooltip(0)"
                />
                <span
                  class="error"
                  v-if="form.errors().has('zip')"
                >
                  {{ form.errors().get('zip') }}
                </span>
              </div>
            </div>

            <!-- Postfach -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyCommunity.postbox') }}</label>
                <input
                  type="text"
                  v-model="form.postbox"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span
                  class="error"
                  v-if="form.errors().has('postbox')"
                >
                  {{ form.errors().get('postbox') }}
                </span>
              </div>
            </div>

            <!-- Ort -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyCommunity.city') }}</label>
                <input
                  type="text"
                  v-model="form.city"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span
                  class="error"
                  v-if="form.errors().has('city')"
                >
                  {{ form.errors().get('city') }}
                </span>
              </div>
            </div>

            <!-- Land -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div
                class="data-form"
                @mouseenter="setInfoTooltip(0)"
              >
                <label class="label-title">{{ $t('newProperty.propertyCommunity.country') }} </label>
                <select
                  ref="community_country"
                  v-model.number="form.country_uid"
                  :disabled="isPropertyReadOnly"
                  class="hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                >
                  <option
                    value="0"
                    disabled
                    selected
                  >
                    {{ $t('select.select_any') }}
                  </option>
                  <option
                    v-for="option in arrCountries"
                    v-bind:key="option.code"
                    :value="option.code"
                  >
                    {{ option.name }}
                  </option>
                </select>
                <span
                  class="error"
                  v-if="form.errors().has('country_uid')"
                >
                  {{ form.errors().get('country_uid') }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="text_left step-button-section"
          @mouseenter="setInfoTooltip(0)"
        >
          <div>
            <button
              type="button"
              @click.prevent="cancel"
              class="step-button-style border-button-style hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.abort') }}
            </button>
            <button
              type="button"
              @click.prevent="save"
              class="step-button-style border-button-style hover-tooltip"
              v-show="!isPropertyReadOnly"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.save') }}
            </button>
            <button
              type="button"
              v-show="back_button_activated"
              @click.prevent="callForPrecedingStep"
              class="step-button-style border-button-style"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.back') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              :style="[primaryColor ? { background: primaryColor } : '']"
              class="step-button-style hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue') }}
            </button>
            <button
              type="button"
              @click.prevent="callForNextStep"
              v-show="isShowNextStepButton"
              class="step-button-enter hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue_enter') }}
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </section>
</template>

<script type="text/javascript">
import form from 'vuejs-form'
import i18n from '@/i18n'
import InfoTooltip from '@/components/helper/InfoTooltip'
import ShowHideTooltip from '@/mixins/ShowHideTooltip'
const { t } = i18n.global
import { mapGetters, mapActions } from 'vuex'
import { evaluateOwnershipSalutationOptions } from '@/helper/PropertyHelper'
import InfoTextHeading from '@/components/helper/InfoTextHeading'
import InfoText from '@/components/helper/InfoText'

export default {
  name: 'PropertyCommunity',
  components: { InfoTextHeading, InfoText, InfoTooltip },
  mixins: [ShowHideTooltip],
  emits: ['callForNextStep', 'callForPrecedingStep', 'callForSaveProperty', 'cancelPropVisible'],
  data() {
    return {
      primaryColor: '',
      fontColor: '',
      arrCountries: [],
      form: form({
        id: 0,
        salutation: '0',
        name: '',
        street: '',
        house_number: '',
        zip: '',
        postbox: '',
        city: '',
        country_uid: '0',
      })
        .rules({
          name: ['max: 50'],
          street: ['max: 25'],
          house_number: ['max: 39'],
          zip: ['max: 5', 'integer'],
          postbox: ['max: 6', 'integer'],
          city: ['max: 25'],
        })
        .messages({
          'name.max': t('newProperty.propertyCommunity.messages.name_max'),
          'street.max': t('newProperty.propertyCommunity.messages.street_max'),
          'house_number.max': t('newProperty.propertyCommunity.messages.house_number_max'),
          'zip.max': t('newProperty.propertyCommunity.messages.zip_max'),
          'zip.integer': t('newProperty.propertyCommunity.messages.zip_integer'),
          'postbox.max': t('newProperty.propertyCommunity.messages.postbox_max'),
          'postbox.integer': t('newProperty.propertyCommunity.messages.postbox_integer'),
          'city.max': t('newProperty.propertyCommunity.messages.city_max'),
        }),
      salutations: [{ value: 1 }, { value: 2 }, { value: 3 }],
      back_button_activated: false,
    }
  },
  mounted() {
    this.initForm()
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : ''
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.primaryColor : ''
    this.getCountries()
    this.showHide()
    this.invokeReference('community_name')
  },
  beforeUnmount() {
    this.saveForm()
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getFormPropertyCommunity',
      'getFormPropertyAddress',
      'isPropertyReadOnly',
      'getArrCountries',
      'infoTooltip',
    ]),

    isNewProperty() {
      return this.$parent.isNewProperty
    },

    ownership_salutation_options() {
      let myOptions = []
      if (
        typeof this.getFormPropertyAddress === 'object' &&
        typeof this.getFormPropertyAddress.federal_state_uid !== 'undefined'
      ) {
        myOptions = evaluateOwnershipSalutationOptions(this.getFormPropertyAddress.federal_state_uid)
      }
      return myOptions
    },

    isShowNextStepButton() {
      let isShow = true
      if (this.$parent.step === this.$parent.maxStep && this.isPropertyReadOnly) {
        isShow = false
      }
      return isShow
    },
  },
  watch: {
    getFormPropertyCommunity() {
      this.initForm()
    },

    getArrCountries(val) {
      this.arrCountries = val.countries
    },
  },
  methods: {
    ...mapActions(['resetFormPropertyCommunity', 'apiGetCountries', 'changeInfoTooltip', 'cityLookup']),

    setInfoTooltip(val) {
      this.changeInfoTooltip(val)
    },

    initForm() {
      for (const [key, value] of Object.entries(this.$store.state.Properties.formPropertyCommunity)) {
        if (typeof value !== 'undefined') {
          this.form[key] = JSON.parse(JSON.stringify(value))
        }
      }
    },

    saveForm() {
      let myKeys = []
      this.resetFormPropertyCommunity()
      myKeys = Object.keys(this.$store.state.Properties.formPropertyCommunity)
      if (typeof myKeys === 'object') {
        myKeys.forEach(function (key) {
          if (typeof this.form[key] !== 'undefined') {
            this.$store.state.Properties.formPropertyCommunity[key] = JSON.parse(JSON.stringify(this.form[key]))
          }
        }, this)
      }
    },

    cancel() {
      this.$emit('cancelPropVisible')
    },

    callForPrecedingStep() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForPrecedingStep')
      } else {
        this.$emit('callForPrecedingStep')
      }
    },

    callForNextStep() {
      if (!this.isPropertyReadOnly) {
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForNextStep')
      } else {
        this.$emit('callForNextStep')
      }
    },

    save() {
      if (!this.isPropertyReadOnly) {
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForSaveProperty')
      }
    },

    invokeReference(ref) {
      this.$nextTick(() => {
        if (!this.$refs[ref]) return
        this.$refs[ref].focus()
      })
    },

    getCountries() {
      this.apiGetCountries()
    },

    searchCity() {
      this.cityLookup(this.form.zip).then((cities) => {
        if (Object.keys(cities).length > 0) {
          this.form.city = cities[0].name
        }
      })
    },

  },
}
</script>

<style type="text/css">
textarea.textarea-field {
  border: 1px solid #bbbbbb;
  height: 150px;
  width: 100%;
}

.Step_SectionPropertyCommunity .tooltip-icon img {
  height: 18px;
  margin-left: 5px;
}

label.label-title.tooltip-icon {
  position: relative;
}

.ToolTipTextBox {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: none;
  max-width: 600px;
  padding: 20px;
  padding: 15px !important;
  position: absolute;
  right: 0;
  top: 35px;
  transform: translate3d(0px, 40px, 0px) !important;
  z-index: 1;
}

/*.Step_SectionPropertyCommunity .tooltip-icon img:hover + .ToolTipTextBox {
    display: block;
}*/
</style>
